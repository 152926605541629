import React, { FC, useContext, useEffect } from 'react'
import Layout from '@/containers/Layout'
import { NavigationContext } from '@/contexts/NavigationContext'
import ModuleLoop from '@/containers/ModulesLoop'
import { graphql } from 'gatsby'
import { SanityPage } from 'web/graphql-types'
import { getUri } from '@/utils/routing'

interface Props {
  data: {
    sanityPage: SanityPage
  }
}

const IndexPage: FC<Props> = props => {
  const { backButton, route, meta } = props.data.sanityPage
  const { contentModules } = props.data.sanityPage
  const { setRoute, setBackButton } = useContext(NavigationContext)

  useEffect(() => {
    setBackButton(backButton || false)
    setRoute(getUri(route?.slug?.current, route?._type || undefined))
  }, [])

  return (
    <Layout meta={meta} displayNone>
      <ModuleLoop modules={contentModules?.modules}></ModuleLoop>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    sanityPage(title: { eq: "Frontpage" }) {
      id
      title
      meta {
        ...metaData
      }
      backButton
      route {
        ... on SanityPage {
          _type
          slug {
            current
          }
        }
        ... on SanityPost {
          _type
          slug {
            current
          }
        }
        ... on SanityStore {
          _type
          slug {
            current
          }
        }
      }
      contentModules {
        ...contentModulesData
      }
    }
  }
`
